import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CardBindingService {

  private subject = new ReplaySubject<UntypedFormGroup>();
  private subject$ = this.subject.asObservable();
  constructor() {
  }

  setForm(form: UntypedFormGroup) {
    this.subject.next(form);
  }

  getForm(): Observable<any> {
    return this.subject$;
  }
}
