import { Accept, AcceptResponse } from './../interfaces/accept.TnC';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Latest } from '../interfaces/latest.TnC';
import { Content } from '../interfaces/content.TnC';
import { environment } from 'src/environments/environment';
import { Update } from '../interfaces/update.TnC';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  })
};

@Injectable({
  providedIn: 'root'
})
export class TncServiceService {
  public apiURL: string = '';
  constructor(private _httpClient: HttpClient) {
    this.apiURL = environment.apiUrl;
  }

  getLatestTnC(sid: string): Observable<Latest> {
    let httpOptionsWithSession = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'sessionId': sid
      })
    }
    return this._httpClient.post<Latest>(this.apiURL + '/card-platform/v1/tc/inquiry/latest', {}, httpOptionsWithSession)
  }
  getUpdatedTnC(sid: string): Observable<Update> {
    let httpOptionsWithSession = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'sessionId': sid
      })
    }
    return this._httpClient.post<Update>(this.apiURL + '/card-platform/v1/tc/inquiry/check/update', {}, httpOptionsWithSession)
  }
  getTnCContent(urlStr: string): Observable<Content> {
    return this._httpClient.get<Content>(urlStr);
  }
  acceptTnC(acceptRequest: Accept, sid: string): Observable<AcceptResponse> {
    let httpOptionsWithSession = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'sessionId': sid
      })
    }
    return this._httpClient.post<AcceptResponse>(this.apiURL + '/card-platform/v1/tc/accept', acceptRequest, httpOptionsWithSession)
  }
}
