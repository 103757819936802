import { SessionStatus } from './../interfaces/session-status';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Auth } from '../interfaces/auth';
import { environment } from 'src/environments/environment';
import { map as rxjsMap } from 'rxjs/operators';
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  })
};

@Injectable({
  providedIn: 'root'
})
export class GetAuthorizeService {

  public apiURL: string = '';
  constructor(private _httpClient: HttpClient) {
    this.apiURL = environment.apiUrl;
  }

 async refreshToken(authorizationCode: string): Promise<boolean> {
    const response =await this._httpClient.post<any>(this.apiURL + '/card-platform/v1/oauth/token/generate', { authorizationCode }, httpOptions).toPromise();
    const newToken = (<any>response).sessionId;
    sessionStorage.setItem('sessionID', newToken);
    return true;
  }
}

