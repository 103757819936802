<div class="header-box fixed-top">
  <div class="vectorOnHeaderBox">
    <div class="row">
      <div class="col-xs-2 col-2" (click)="backClicked()" style="line-height: 30px">
        <img src="/assets/BackIcon.png" height="20" />
      </div>
      <div class="col-xs-8 col-8 page-header">ข้อมูลบัตร</div>
      <div class="col-xs-2 col-2"></div>
    </div>
  </div>
</div>
<div class="leftCorner"><img src="/assets/LeftCorner.png" height="12" /></div>
<div class="rightCorner"><img src="/assets/RightCorner.png" height="12" /></div>
<div class="NameChangeSuccessBox" [ngClass]="{ showNCS: nameChanged }">
  <img src="/assets/ic_check-circle.png" height="24" style="vertical-align: middle; padding-left: 8px" />&nbsp; แก้ไขชื่อเรียกบัตรสำเร็จ
</div>
<div class="NameChangeFailedBox" [ngClass]="{ showNCS: nameCannotChanged }">
  <img src="/assets/ic_alert.png" height="24" style="vertical-align: middle; padding-left: 8px" />แก้ไขชื่อเรียกบัตรไม่สำเร็จ
</div>
<div class="unselectable page-body">
  <p class="sectionHeader">ข้อมูลบัตร</p>
  <button id="receiveCRefNo" style="display: none" (click)="initData()"></button>
  <div class="row">
    <div class="col col-6 cardInfoHeader">ชื่อเรียกบัตร</div>
    <div class="col col-5 cardInfoDetail nickName" *ngIf="getIsLoaded">
      {{ carddetails.cardNickName }}
    </div>
    <div class="col col-1" style="display: contents">
      <span (click)="openDialog()" *ngIf="getIsLoaded">
        <img src="/assets/ic_edit.png" height="24" mat-button />
      </span>
    </div>
  </div>
  <div class="row" style="margin-top: 8px">
    <div class="col cardInfoHeader">ชื่อเจ้าของบัตร</div>
    <div class="col cardInfoDetail englishName" *ngIf="getIsLoaded" style="overflow: visible">
      {{ carddetails.cardHolderNameEN }}
    </div>
  </div>
  <div class="sensitiveBox">
    <div class="row" style="margin-top: 8px" tabindex="-1" role="text">
      <div class="col-5 cardInfoHeader">ชื่อบนบัตร</div>
      <div class="col cardInfoDetail" *ngIf="getIsLoaded" [attr.aria-labelledby]="maskCardNum ? '' : 'embName'">
        <span id="mark-cvv" [attr.aria-hidden]="true" class="englishName">
          {{ maskCardNum ? "*****" : encSensitiveInfo.encEmbossName }}
        </span>
        <span *ngIf="encSensitiveInfo?.encEmbossName" id="embName" class="d-none" [attr.aria-hidden]="maskCardNum">
          {{ encSensitiveInfo.encEmbossName | SpacePipe }}
        </span>
      </div>
    </div>
    <div class="row" style="margin-top: 8px" tabindex="-1" role="text">
      <div class="col-5 cardInfoHeader">หมายเลขบัตร</div>
      <div class="col cardInfoDetail" *ngIf="getIsLoaded" [attr.aria-labelledby]="
          maskCardNum ? 'last-4-card-number' : 'card-number'
        ">
        <span [attr.aria-hidden]="true">
          {{
          maskCardNum
          ? getFormattedCardNumber(carddetails.maskCardNo)
          : getFormattedCardNumber(encSensitiveInfo.encCardNo)
          }}
        </span>
        <span *ngIf="carddetails?.maskCardNo" id="last-4-card-number" class="d-none" [attr.aria-hidden]="!maskCardNum">
          4 ตัวสุดท้าย
          {{ getLast4CardNumber(carddetails.maskCardNo) | SpacePipe }}
        </span>
        <span *ngIf="encSensitiveInfo?.encCardNo" id="card-number" class="d-none" [attr.aria-hidden]="maskCardNum">
          {{ getFormattedCardNumber(encSensitiveInfo.encCardNo) }}
        </span>
      </div>
    </div>

    <div class="row" style="margin-top: 8px" tabindex="-1" role="text">
      <div class="col-5 cardInfoHeader">วันหมดอายุบัตร</div>
      <div class="col cardInfoDetail" *ngIf="getIsLoaded" [attr.aria-labelledby]="maskCardNum ? '' : 'exp-date'">
        <span id="mark-exp-date" [attr.aria-hidden]="true">
          {{ maskCardNum ? "**/**" : encSensitiveInfo.encExpiryDate }}
        </span>
        <span *ngIf="encSensitiveInfo?.encExpiryDate" id="exp-date" class="d-none" [attr.aria-hidden]="maskCardNum">
          {{ encSensitiveInfo.encExpiryDate | SpacePipe : "slash" }}
        </span>
      </div>
    </div>
    <div class="row" style="margin-top: 8px" tabindex="-1" role="text">
      <div class="col-5 cardInfoHeader">รหัส CVV</div>
      <div class="col cardInfoDetail" *ngIf="getIsLoaded" [attr.aria-labelledby]="maskCardNum ? '' : 'cvv'">
        <span id="mark-cvv" [attr.aria-hidden]="true">
          {{ maskCardNum ? "***" : encSensitiveInfo.encCvv }}
        </span>
        <span *ngIf="encSensitiveInfo?.encCvv" id="cvv" class="d-none" [attr.aria-hidden]="maskCardNum">
          {{ encSensitiveInfo.encCvv | SpacePipe }}
        </span>
      </div>
    </div>
    <div class="row maskUnmaskToggle" *ngIf="getIsLoaded" style="margin-top: 8px" >
      <div *ngIf="maskCardNum">
        <span role="text" aria-label="กดเพื่อแสดงข้อมูลบัตร" (click)="toggleSensitiveCardInfo()">
          แสดงข้อมูลบัตร
        </span>
      </div>
      <div *ngIf="maskCardNum==false">
        <span role="text" aria-label="กดเพื่อซ่อนข้อมูลบัตร" (click)="toggleSensitiveCardInfo()">
          ซ่อนข้อมูลบัตร
        </span>
      </div>
    </div>
  </div>

  <div class="row" style="margin-top: 12px">
    <div class="col cardInfoHeader">สถานะบัตร</div>
    <div class="col cardInfoDetail" [style.color]="getColorbyStatusCode()" *ngIf="getIsLoaded">
      {{ getStringbyStatusCode() }}
    </div>
  </div>
  <table [style.display]="showDetailBoxforStatusCode5()" class="sc5Box" cellspacing="0" cellpadding="0">
    <tr>
      <td class="infoIcon">
        <img src="/assets/ic_info.png" height="16" />
      </td>
      <td>
        <table cellspacing="0" cellpadding="0">
          <tr>
            <td>
              <p class="sc5Text" style="margin-bottom: 8px !important">
                บัตรหมดอายุ กรุณาสมัครบัตรใหม่เพื่อทำ รายการต่อ
              </p>
            </td>
          </tr>

          <tr>
            <td>
              <a (click)="openTnc()" class="sc5Button" style="text-decoration: none">
                สมัครบัตรใหม่
              </a>
            </td>
          </tr>
        </table>
      </td>
    </tr>
  </table>
  <table [style.display]="showDetailBoxforStatusCode11()" class="sc11Box" cellspacing="0" cellpadding="0">
    <tr>
      <td class="infoIcon">
        <img src="/assets/ic_info-blue.png" height="16" />
      </td>
      <td>
        <table cellspacing="0" cellpadding="0">
          <tr>
            <td>
              <p class="sc11Text">
                เมื่อได้รับบัตรแล้ว กรุณาเปิดบัตรเพื่อเริ่มการใช้งาน
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <button class="sc11Button" (click)="openCardClicked()">
                เปิดบัตร
              </button>
            </td>
          </tr>
        </table>
      </td>
    </tr>
  </table>
  <table [style.display]="showDetailBoxforStatusCode121710()" class="sc5Box" cellspacing="0" cellpadding="0">
    <tr>
      <td class="infoIcon">
        <img src="/assets/ic_info.png" height="16" />
      </td>
      <td>
        <table cellspacing="0" cellpadding="0">
          <tr>
            <td>
              <p class="sc5Text">
                บัตรถูกระงับการใช้งาน กรุณาติดต่อสอบถาม ผ่านทางอีเมล
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <hr style="color: #e17f00" />
            </td>
          </tr>
          <tr>
            <td style="display: inline-block; vertical-align: middle">
              <img src="/assets/Mailicon.png" height="16" style="margin-top: -6px" />&nbsp;
              <a href="mailto:call.callcenter@krungthai.com" style="text-decoration: none !important">
                <span class="scEmail">call.callcenter@krungthai.com</span>
              </a>
            </td>
          </tr>
        </table>
      </td>
    </tr>
  </table>
  <table [style.display]="showDetailBoxforStatusCode15()" class="sc5Box" cellspacing="0" cellpadding="0">
    <tr>
      <td class="infoIcon">
        <img src="/assets/ic_info.png" height="16" />
      </td>
      <td>
        <p class="sc5Text">
          ไม่สามารถทำธุรกรรมใดๆ ผ่านบัตรใบนี้ได้
          กรุณาปิดการระงับบัตรเพื่อใช้งานต่อ
        </p>
      </td>
    </tr>
  </table>
  <mat-divider style="margin-top: 24px; margin-bottom: 24px"></mat-divider>
  <p class="withdrawalLimitArea">วงเงินของบัตร</p>
  <div class="row">
    <div class="col cardInfoHeader">วงเงินใช้จ่ายต่อวัน</div>
    <div class="col cardInfoDetail currencyValue" *ngIf="getIsLoaded">
      {{ carddetails.cardUsageLimitSpending ?? "0" }}
      <span class="currencyName">บาท</span>
    </div>
  </div>
  <div class="row" [ngClass]="{
      'd-none': carddetails.cardUsageLimitWithdrawATM ?? '0' === '0'
    }">
    <div class="col cardInfoHeader">วงเงินกดเงินสดต่อวัน</div>
    <div class="col cardInfoDetail currencyValue" *ngIf="getIsLoaded">
      {{ carddetails.cardUsageLimitWithdrawATM ?? "0" }}
      <span class="currencyName">บาท</span>
    </div>
  </div>
  <mat-divider style="margin-top: 24px; margin-bottom: 24px"></mat-divider>
  <div class="gotoTnCField">
    <a [routerLink]="['/cardInfoTnC']" [queryParams]="{
        sessionId: sid,
        cardRefNo: cardRefNo,
        webviewToken: webviewToken,
        authorizationCode: authToken,
        nextAction: '/cardInfo'
      }">
      <div class="row">
        <div class="col tncField">ข้อตกลงและเงื่อนไข</div>
        <div class="col cardInfoDetail">
          <img src="/assets/rightArrow.png" height="14" style="padding-right: 2px; margin-bottom: 5px" />
        </div>
      </div>
    </a>
  </div>
</div>