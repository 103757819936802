// import { Inject, Injectable, Injector } from '@angular/core';
// import {
//   HttpErrorResponse,
//   HttpEvent,
//   HttpHandler,
//   HttpInterceptor,
//   HttpRequest,
//   HttpResponse,
//   HttpStatusCode,
// } from '@angular/common/http';

// import {
//   catchError,
//   Observable,
//   throwError,
// } from 'rxjs';
// import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
// import { PopUpErrorCodeComponent } from '../pop-up-error-code/pop-up-error-code.component';
// import { HTTP_ERROR } from '../configs/error.config';
// import { PopUpNoInternetComponent } from '../pop-up-no-internet/pop-up-no-internet.component';
// import { LoaderService } from '../shared/loading/loader.service';
// @Injectable()
// export class EventInterceptorService implements HttpInterceptor {

//   private requests: HttpRequest<any>[] = [];
//   constructor(
//     private modalService: BsModalService,
//     private loaderService: LoaderService
//   ) { }

//   removeRequest(req: HttpRequest<any>) {
//     const i = this.requests.indexOf(req);
//     if (i >= 0) {
//       this.requests.splice(i, 1);
//     }
//     this.loaderService.isInterceptorLoading.next(this.requests.length > 0);
//   }

//   intercept(
//     req: HttpRequest<any>,
//     next: HttpHandler
//   ): Observable<HttpEvent<any>> {
//     let reqNext = req;
//     let sessionID = sessionStorage.getItem('sessionID');
//     if (req.url.indexOf('oauth/token/generate') < 0) {
//       reqNext = req.clone({
//         setHeaders: {
//           'sessionId': sessionID || ''
//         },
//       });
//     }

//     this.loaderService.isInterceptorLoading.next(true);


//     return new Observable(observer => {
//       const subscription = next
//         .handle(reqNext)
//         .pipe(
//           catchError((error: HttpErrorResponse) => {
//             this.removeRequest(reqNext);
//             return this.handleError(error);
//           })
//         )
//         .subscribe(
//           (event: HttpEvent<any>) => {
//             if (event instanceof HttpResponse) {
//               // logger.debug('HttpEvent', event);
//               this.removeRequest(reqNext);
//               observer.next(event);
//             }
//           },
//           (error: HttpErrorResponse) => {
//             //logger.debug('HttpErrorResponse', error);
//             this.removeRequest(reqNext);
//             observer.error(error);
//           },
//           () => {
//             // logger.debug('complete HttpEvent', req);
//             this.removeRequest(reqNext);
//             observer.complete();
//           }
//         );
//       // remove request from queue when cancelled
//       return () => {
//         // logger.debug('remove request from queue when cancelled', req);
//         this.removeRequest(req);
//         subscription.unsubscribe();
//       };
//     });

//     // return next.handle(reqNext).pipe(
//     //   catchError((error: HttpErrorResponse) => {
//     //     this.removeRequest(reqNext);
//     //     return this.handleError(error);
//     //   })
//     // )
//     // ;
//   }
//   private handleError(error: HttpErrorResponse) {
//     if (error.status !== HttpStatusCode.Ok) {
//       if (navigator.onLine) {
//         this.showGenericError(error.status);
//       } else {
//         this.showNoInternet();
//       }
//     }
//     return throwError(() => error);
//   }

//   private showGenericError(code: number) {
//     setTimeout(() => {
//       const initialState: ModalOptions = {
//         initialState: {
//           code: code,
//         },
//         class: 'modal-dialog-centered'
//       };
//       this.modalService.show(PopUpErrorCodeComponent, initialState);
//     }, 0);
//   }

//   private showNoInternet() {
//     const initialState: ModalOptions = {
//       class: 'modal-dialog-centered'
//     };
//     this.modalService.show(PopUpNoInternetComponent, initialState);
//   }
// }

import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpStatusCode,
} from '@angular/common/http';

import {
  catchError,
  finalize,
  Observable,
  throwError,
  tap,
} from 'rxjs';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { PopUpErrorCodeComponent } from '../pop-up-error-code/pop-up-error-code.component';
import { PopUpNoInternetComponent } from '../pop-up-no-internet/pop-up-no-internet.component';
import { LoaderService } from '../shared/loading/loader.service';

@Injectable()
export class EventInterceptorService implements HttpInterceptor {
  private requests: HttpRequest<any>[] = [];

  constructor(
    private modalService: BsModalService,
    private loaderService: LoaderService
  ) { }

  removeRequest(req: HttpRequest<any>) {
    const i = this.requests.indexOf(req);
    if (i >= 0) {
      this.requests.splice(i, 1);
    }
    this.loaderService.isInterceptorLoading.next(this.requests.length > 0);
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let sessionID = sessionStorage.getItem('sessionID');
    let headers = req.headers;
    if (req.url.indexOf('oauth/token/generate') < 0) {
      headers = headers.set('sessionId', sessionID || '');
    }
    let reqNext = req.clone({ headers });

    this.loaderService.isInterceptorLoading.next(true);

    return next.handle(reqNext).pipe(
      tap((event) => {
        if (event instanceof HttpResponse) {
          this.removeRequest(reqNext);
        }
      }),
      catchError((error: HttpErrorResponse) => {
        this.removeRequest(reqNext);
        return this.handleError(error);
      }),
      finalize(() => {
        this.removeRequest(reqNext);
      })
    );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status !== HttpStatusCode.Ok) {
      this.showGenericError(error.status);
    } else {
      this.showNoInternet();
    }
    return throwError(() => error);
  }

  private showGenericError(code: number) {
    const initialState: ModalOptions = {
      initialState: {
        code: code,
      },
      class: 'modal-dialog-centered',
    };
    this.modalService.show(PopUpErrorCodeComponent, initialState);
  }

  private showNoInternet() {
    const initialState: ModalOptions = {
      class: 'modal-dialog-centered',
    };
    this.modalService.show(PopUpNoInternetComponent, initialState);
  }
}
