import { CardDetailService } from './../services/card-detail.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';
import emojiStrip from 'emoji-strip'
import { tap, map } from 'rxjs/operators';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { isIosGreaterThan14OrAndroid, log } from "../utilities"

@Component({
  selector: 'app-change-card-name',
  templateUrl: './change-card-name.component.html',
  styleUrls: ['./change-card-name.component.css']
})
export class ChangeCardNameComponent implements OnInit {
  isButtonDisabled: boolean = true;
  cardNickName: string = "";
  prevCardNickName: string = "";
  charCount: number = 0;
  public sid: string = ''
  public cardRefNo: string = '';
  public cardNickNameChanged: boolean = false;
  bsModalRef?: BsModalRef;
  isLoading = false;
  public safeBottom: any;
  public pattern = /[^a-zA-Z0-9\u0E00-\u0E7F\s]|(?:\s{2,})/gi;
  get getLenNickName() {
    return this.cardNickName.length;
  }

  constructor(
    public dgRef: MatDialogRef<ChangeCardNameComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cds: CardDetailService,
    private modalService: BsModalService,
  ) { }

  getSubmitButtonDisabled() {
    return false
      || this.isLoading
      || this.cardNickName.length <= 0
      || this.pattern.test(this.cardNickName)
      || this.cardNickName.trim() === this.prevCardNickName.trim()
  }

  onBlur() {
    if (isIosGreaterThan14OrAndroid()) {
      return
    }
    setTimeout(() => {
      window.document.body.scrollTo({ top: 0 })
    }, 10)
  }

  ngOnInit(): void {
    this.cardNickName = this.data.cardNickName;
    this.prevCardNickName = this.data.cardNickName;
    this.sid = this.data.sessionId;
    this.cardRefNo = this.data.cardRefNo;
    this.nickNameChanged(this.cardNickName);
    this.isButtonDisabled = true;
    var userAgent = navigator.userAgent || navigator.vendor;
    if (/android/i.test(userAgent)) {
      this.safeBottom = "margin-bottom: 16px"
    } else {
      this.safeBottom = "margin-bottom: 42px"
    }
  }
  nickNameChanged(cnn: string) {
    this.charCount = this.cardNickName.length;
    if (this.pattern.test(cnn) || cnn.trim() === this.prevCardNickName.trim()) {
      this.isButtonDisabled = true;
      this.cardNickNameChanged = true;
    } else {
      this.isButtonDisabled = false;
    }
  }
  isCountZero() {
    if (this.charCount <= 0) {
      this.cardNickNameChanged = false;
      this.isButtonDisabled = true;
      return 'block';
    } else {
      return 'none';
    }
  }
  submitNickNameChange() {
    this.isLoading = true;
    this.cds.updateCardNickName(this.cardRefNo, this.cardNickName.trim(), this.sid)
      .subscribe((res) => {
        this.dgRef.close(res);
        this.isLoading = false;
        log("in submitNickNameChange ->", res);
      });
  }

  keypressEmojiStrip(e: any) {
    if (this.pattern.test(e.key)) {
      e.preventDefault();
      e.stopImmediatePropagation();
      e.stopPropagation();
    }
    if (e.target.value.length + e.key.length > 20) {
      e.preventDefault();
      e.stopImmediatePropagation();
      e.stopPropagation();
    }
    e.target.value = e.target.value.replaceAll(this.pattern, "");
  }
  keypressEmojiStripInput(e: any) {
    e.target.value = e.target.value.replaceAll(this.pattern, "");
    this.cardNickName = e.target.value;
    let len = e.target.value.split("");
    if (len.length > 20) {
      e.target.value = '';
      for (var i = 0; i < 20; i++) {
        e.target.value += len[i];
      }
      this.cardNickName = e.target.value;
      e.preventDefault();
      e.stopImmediatePropagation();
      e.stopPropagation();
    }
  }
  onChange(text: string) {
    let length = text.length;
    if (length > 20) {
      let temp = '';
      this.cardNickName = '';
      temp = text.slice(0, 20);
      this.cardNickName = temp;
      this.cardNickName = this.cardNickName.trim();
    } else {
      this.cardNickName = text;
      this.cardNickName = this.cardNickName.trim();
    }
  }

  disableCutCopy(event: any) {
    event.preventDefault();
    navigator.clipboard.writeText('').then(function () {
      log("Copying and Cutting is disabled");
    }, function (err) {
      log("unable to get clipboard");
    })
  }
}
