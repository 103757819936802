<div class="header-box fixed-top">
  <div class="vectorOnHeaderBox">
    <div class="row">
      <div
        class="col-xs-2 col-2"
        (click)="backClicked()"
        style="line-height: 30px"
      >
        <img src="/assets/BackIcon.png" height="20" />
      </div>
      <div class="col-xs-8 col-8 text-center page-header">ยืนยันข้อมูล</div>
      <div class="col-xs-2 col-2"></div>
    </div>
  </div>
</div>
<div class="leftCorner"><img src="/assets/LeftCorner.png" height="12" /></div>
<div class="rightCorner"><img src="/assets/RightCorner.png" height="12" /></div>
<div class="page-body unselectable">
  <div class="pad16">
    <div style="height: 100px"></div>
    <img src="/assets/PaotangPayPlay.png" class="card-Image" />
    <p class="sectionHeader">ข้อมูลบัตร Play</p>
    <mat-grid-list cols="2" rowHeight="32">
      <mat-grid-tile>
        <div class="cardInfoHeader">ชื่อ</div>
      </mat-grid-tile>
      <mat-grid-tile>
        <div class="cardInfoDetail">{{ firstName }}</div>
      </mat-grid-tile>
      <mat-grid-tile>
        <div class="cardInfoHeader">นามสกุล</div>
      </mat-grid-tile>
      <mat-grid-tile>
        <div class="cardInfoDetail">{{ lastName }}</div>
      </mat-grid-tile>

      <mat-grid-tile>
        <div class="cardInfoHeader">วงเงินใช้จ่ายต่อวัน</div>
      </mat-grid-tile>
      <mat-grid-tile>
        <div class="cardInfoDetail">
          {{ cardDetails.cardUsageLimitSpending }} บาท
        </div>
      </mat-grid-tile>
      <!-- <mat-grid-tile
      [ngClass]="{
        'd-none': cardDetails.cardUsageLimitWithdrawATM === '0'
      }"
    >
      <div class="cardInfoHeader">วงเงินกดเงินสดต่อวัน</div>
    </mat-grid-tile>
    <mat-grid-tile
      [ngClass]="{
        'd-none': cardDetails.cardUsageLimitWithdrawATM === '0'
      }"
    >
      <div class="cardInfoDetail">
        {{ cardDetails.cardUsageLimitWithdrawATM }} บาท
      </div>
    </mat-grid-tile> -->
      <mat-grid-tile>
        <div class="cardInfoHeader">หมายเลขบัตร</div>
      </mat-grid-tile>
      <mat-grid-tile>
        <div class="cardInfoDetail">
          {{ getFormattedCardNumber(cardDetails.maskCardNo) }}
        </div>
      </mat-grid-tile>
      <mat-grid-tile>
        <div class="cardInfoHeader">วันหมดอายุ (ดด / ปป)</div>
      </mat-grid-tile>
      <mat-grid-tile>
        <div class="cardInfoDetail">{{ expDate }}</div>
      </mat-grid-tile>
      <mat-grid-tile>
        <div class="cardInfoHeader">ชื่อเรียกบัตร</div>
      </mat-grid-tile>
      <mat-grid-tile>
        <div class="cardInfoDetail">{{ cardNickName }}</div>
      </mat-grid-tile>
    </mat-grid-list>
  </div>
  <div class="actionArea d-grid">
    <button class="btn submitStyle" [style]="safeBottom" (click)="submitCard(); onSendGA()">
      ยืนยัน
    </button>
  </div>
</div>
