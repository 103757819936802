<div style="padding: 16px; padding-bottom: 0px">
  <h3 class="dialogTitle" mat-dialog-title>แก้ไขชื่อเรียกบัตร</h3>
  <span style="float: right; -webkit-appearance: unset;" [mat-dialog-close]>
    <img src="/assets/ic_close.png" height="24" />
  </span>

  <div mat-dialog-content style="clear: both">
    <mat-divider></mat-divider>
    <h4 class="contentTitle">ชื่อเรียกบัตรที่จะแสดงบนแอปฯ</h4>
    <input (blur)="onBlur()" class="inputStyle" (copy)="disableCutCopy($event)" (cut)="disableCutCopy($event)" (input)="keypressEmojiStripInput($event)" (keypress)="keypressEmojiStrip($event)" maxlength="20" name="cardNickName" [(ngModel)]="cardNickName" (ngModelChange)="nickNameChanged(cardNickName)" (paste)="$event.stopImmediatePropagation(); $event.stopPropagation(); $event.preventDefault();" placeholder="ชื่อเรียกบัตร" />
    <p class="CharCountZeroError" [style.display]="isCountZero()">
      กรุณากรอกชื่อเรียกบัตรที่จะแสดงบนแอปฯ
    </p>
    <p class="charCount">{{ getLenNickName }}/20 ตัวอักษร</p>
  </div>
</div>
<div class="actionArea d-grid">
  <!-- <button class="btn submitStyle" [style]="safeBottom" [disabled]="isButtonDisabled || isLoading" (click)="submitNickNameChange()"> -->
  <button class="btn submitStyle" [style]="safeBottom" [disabled]="getSubmitButtonDisabled()" (click)="submitNickNameChange()">
    <span>ยืนยัน</span>
    <mat-spinner *ngIf="isLoading" style="float: right" [diameter]="20"></mat-spinner>
  </button>
</div>