<div>
  <div class="header-box fixed-top">
    <div class="vectorOnHeaderBox">
      <div class="row">
        <div class="col-xs-2 col-2" (click)="backClicked()">
          <img src="/assets/BackIcon.png" height="20" />
        </div>
        <div class="col-xs-8 col-8 text-center page-header">ข้อตกลงและเงื่อนไข</div>
        <div class="col-xs-2 col-2"></div>
      </div>
    </div>
  </div>
  <div class="header-bd-left"></div>
  <div class="header-bd-wh-left"></div>

  <div class="header-bd-right"></div>
  <div class="header-bd-wh-right"></div>
  <div class="tncContentContainer" style="top:100px;position: relative;">
    <div
      style="font-family: 'Kanit'"
      [ngStyle]="showActionArea ? { 'padding-bottom': '100px' } : {}"
      class="unselectable"
      [innerHTML]="contentDesc"
    ></div>
  </div>
</div>
<div class="actionArea fixed-bottom d-grid" *ngIf="showActionArea">
  <div class="form-check" style="margin-left: 16px; margin-top: 16px">
    <input
      class="form-check-input"
      style="padding: 8px"
      type="checkbox"
      value=""
      id="flexCheckDefault"
      [(ngModel)]="isDisabled"
    />
    <label class="form-check-label" for="flexCheckDefault" class="checkLabel">
      ยอมรับเงื่อนไขการใช้บริการ
    </label>
  </div>
  <button
    class="btn submitStyle"
    (click)="acceptTnC()"
    [disabled]="!isDisabled"
  >
    ยืนยัน
  </button>
</div>
