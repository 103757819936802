import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-pop-up-maximum-card',
  templateUrl: './pop-up-maximum-card.component.html',
  styleUrls: ['./pop-up-maximum-card.component.css']
})
export class PopUpMaximumCardComponent implements OnInit {

  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit(): void {
  }
}
