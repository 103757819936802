import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { HTTP_ERROR, ANDROID_ERROR_CODE, IOS_ERROR_CODE } from '../configs/error.config';
@Component({
  selector: 'app-pop-up-error-code',
  templateUrl: './pop-up-error-code.component.html',
  styleUrls: ['./pop-up-error-code.component.css']
})
export class PopUpErrorCodeComponent implements OnInit {
  public errorCode: string = '';
  public code: HTTP_ERROR | undefined;
  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() {
    const ua = navigator.userAgent.toLowerCase();
    const isAndroid = ua.indexOf('android') > -1;

    if (isAndroid) {
      switch (this.code) {
        case HTTP_ERROR.CODE_400: this.errorCode = `[${ANDROID_ERROR_CODE.HCA001}]`; break;
        case HTTP_ERROR.CODE_404: this.errorCode = `[${ANDROID_ERROR_CODE.HCA002}]`; break;
        case HTTP_ERROR.CODE_500: this.errorCode = `[${ANDROID_ERROR_CODE.HCA003}]`; break;
        case HTTP_ERROR.CODE_502: this.errorCode = `[${ANDROID_ERROR_CODE.HCA004}]`; break;
        case HTTP_ERROR.CODE_503: this.errorCode = `[${ANDROID_ERROR_CODE.HCA005}]`; break;
        default: this.errorCode = `[${ANDROID_ERROR_CODE.HCA999}]`; break;
      }
    } else {
      switch (this.code) {
        case HTTP_ERROR.CODE_400: this.errorCode = `(${IOS_ERROR_CODE.HCI001})`; break;
        case HTTP_ERROR.CODE_404: this.errorCode = `(${IOS_ERROR_CODE.HCI002})`; break;
        case HTTP_ERROR.CODE_500: this.errorCode = `(${IOS_ERROR_CODE.HCI003})`; break;
        case HTTP_ERROR.CODE_502: this.errorCode = `(${IOS_ERROR_CODE.HCI004})`; break;
        case HTTP_ERROR.CODE_503: this.errorCode = `(${IOS_ERROR_CODE.HCI005})`; break;
        default: this.errorCode = `(${IOS_ERROR_CODE.HCI999})`; break;
      }
    }

  }
  closeModal() {
    location.reload()
    this.bsModalRef.hide()
  }

}
