import { CardInfo, LookupRequest, LookupResponse } from './../interfaces/detail.Card';
import { PopUpCardNotActivatedComponent } from './../pop-up-card-not-activated/pop-up-card-not-activated.component';
import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Validators, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { CreditCardValidators, CreditCard } from 'angular-cc-library';
import { defer, firstValueFrom, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { GetAuthorizeService } from '../services/get-authorize.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import isOnline from 'is-online';
import jwt_decode from "jwt-decode";
import { CardDetailService } from '../services/card-detail.service';
import { TncServiceService } from '../services/tnc-service.service';
import { Update } from '../interfaces/update.TnC';
import { CardBindingService } from '../services/card-binding.service';
import { isIosGreaterThan14OrAndroid, log } from '../utilities';
declare function sendGADataToNative(data: any): any;

@Component({
  selector: 'app-card-bind',
  templateUrl: './card-bind.component.html',
  styleUrls: ['./card-bind.component.css']
})
export class CardBindComponent implements OnInit, OnDestroy {
  public demoForm: UntypedFormGroup = {} as UntypedFormGroup;
  public submitted = false;
  public canSubmit = false;
  public objAuth = {};
  public decodedwebViewToken: any = {};
  bsModalRef?: BsModalRef;
  networkStatus: boolean = false;
  public webviewToken: string = '';
  public authToken: string = '';
  public sid: string = '';
  public validExp: boolean = false;
  public validCC: boolean = false;
  public validNN: boolean = false;
  public charCount: number = 0;
  public cardNickName: string = "";
  public cardLookupResponse: any = {};
  public carddetails: any = {};
  public updatedTnC: Update = {} as Update;
  public contentDesc: string = '';
  public subscription: Subscription = new Subscription;
  isHuaweiPhone: boolean = false;
  public safeBottom: any;
  public pattern = /[^a-zA-Z0-9\u0E00-\u0E7F\s]|(?:\s{2,})/gi;
  public isCCError: boolean = false;
  public isExpError: boolean = false;
  public isNickNameError: boolean = false;
  public type$ = defer(() => this.demoForm.get('creditCard')!.valueChanges).pipe(map((num: string) => CreditCard.cardType(num)));

  inputState = {
    isFocusing: false,
  }
  constructor(
    private getAuth: GetAuthorizeService,
    private fb: UntypedFormBuilder,
    public _location: Location,
    private modalService: BsModalService,
    private activatedRoute: ActivatedRoute,
    private _cardDetailService: CardDetailService,
    private _cardBindingService: CardBindingService,
    private _tncService: TncServiceService,
    private _router: Router
  ) {
    this.activatedRoute.queryParams.subscribe(prm => {
      this.webviewToken = prm['webviewToken'];
      this.authToken = prm['authorizationCode'];
      this.sid = sessionStorage.getItem('sessionID') || '';
    });
  }

  onBlur() {
    if (isIosGreaterThan14OrAndroid()) {
      return
    }
    setTimeout(() => {
      if (this.inputState.isFocusing) {
        return
      }
      window.document.body.scrollTo({ top: 0 })
    }, 10)
  }

  onFocus() {
    if (isIosGreaterThan14OrAndroid()) {
      return
    }
    this.inputState.isFocusing = true
    setTimeout(() => {
      this.inputState.isFocusing = false
    }, 500)
  }

  get expErrorText() {
    if (this.getErrorExpDate() == 'expDate') {
      return 'รูปแบบวันหมดอายุบัตรไม่ถูกต้อง';
    } else if (this.getErrorExpDate() == 'required') {
      return 'กรุณากรอกวันหมดอายุบัตร';
    } else {
      return '';
    }
  }
  get expError() {
    return this.getErrorExpDate();
  }
  get getLenNickName() {
    return this.cardNickName.length;
  }
  public ngOnInit() {
    this.demoForm = this.fb.group({
      creditCard: ['', [CreditCardValidators.validateCCNumber]],
      expDate: ['', [Validators.required, CreditCardValidators.validateExpDate]],
      cardNickName: ['บัตร Play', [Validators.required, Validators.maxLength(20), Validators.minLength(1)]],
    });

    var userAgent = navigator.userAgent;
    if (/huawei/i.test(userAgent)) {
      this.isHuaweiPhone = true;
    }
    this._cardBindingService.getForm().subscribe(form => {
      if (form) {
        this.demoForm = form;
      }
    });
    this.cardNickName = this.demoForm.get('cardNickName')?.value;
    (async () => { this.networkStatus = await isOnline(); })();
    var userAgent = navigator.userAgent || navigator.vendor;
    if (/android/i.test(userAgent)) {
      this.safeBottom = "margin-bottom: 16px"
    } else {
      this.safeBottom = "margin-bottom: 42px"
    }
    this.decodedwebViewToken = jwt_decode(this.webviewToken);
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  public goToNextField(controlName: string, nextField: HTMLInputElement) {
    if (this.demoForm.get(controlName)?.valid) {
      nextField.focus();
    }
  }
  async onSubmit(demoForm: UntypedFormGroup) {
    log("Next Clicked");
    if (demoForm.invalid || this.submitted) {
      return;
    }
    this.submitted = true;
    let ccNum = this.demoForm.get('creditCard')?.value;
    ccNum = ccNum.replace(/\s/g, '');
    var expInpDateVal = this.demoForm.get('expDate')?.value;
    var expDYV = expInpDateVal.split('/')[1].toString().trim();
    var expDMV = expInpDateVal.split('/')[0].toString().trim();
    var expDate4LookupReq = expDMV + '' + expDYV;
    let clreq: LookupRequest = {
      cardNo: ccNum,
      cid: '',
      cardExpiryDate: expDate4LookupReq
    };
    log("request Object -> ", clreq);
    log("sessionID -> ", this.sid);
    const cl: LookupResponse = await firstValueFrom(
      this._cardDetailService.cardLookup(clreq, this.sid)
    );
    this.cardLookupResponse = cl;
    log("LookupResponse -> ", cl);
    this.carddetails = cl;
    this.carddetails.cardNo = ccNum;
    if ((this.carddetails) && this.carddetails.statusCd !== "0000") {
      this.submitted = false;
      this.bsModalRef = this.modalService.show(PopUpCardNotActivatedComponent, {
        class: 'modal-dialog-centered',
        ignoreBackdropClick: true,
        initialState: {
          statusCode: this.carddetails.statusCd === undefined ? "" : "(" + this.carddetails.statusCd + ")"
        }
      });
      return;
    }

    const tc = await firstValueFrom(this._tncService.getUpdatedTnC(this.sid));
    log('TnC Response->', tc);
    this.updatedTnC = tc;

    log(this.carddetails);

    log("Card Lookup Response", this.cardLookupResponse);
    log("updatedTnC", this.updatedTnC);
    this._cardBindingService.setForm(demoForm);
    if (this.updatedTnC.tncList[0].isUpdateTc) {
      this._router.navigate(['/cardInfoTnC'], {
        queryParams: {
          sessionId: this.sid,
          cardRefNo: this.cardLookupResponse.cardRefNo,
          webviewToken: this.webviewToken,
          authorizationCode: this.authToken,
          cardnickname: this.cardNickName,
          nextAction: '/card-bind-confirm'
        }
      });
    } else {
      this._router.navigate(['/card-bind-confirm'], {
        state: {
          cardNum: ccNum,
        },
        queryParams: {
          sessionId: this.sid,
          cardRefNo: this.cardLookupResponse.cardRefNo,
          webviewToken: this.webviewToken,
          authorizationCode: this.authToken,
          cardnickname: this.cardNickName
        }
      });
    }
  }

  backClicked() {
    window.location.href = this.decodedwebViewToken?.backUrl
  }
  CheckifValidExp() {
    this.validExp = !this.demoForm.get('expDate')?.valid ?? false;
  }
  CheckifValidCC() {
    this.validCC = !this.demoForm.get('creditCard')?.valid ?? false;
  }
  CheckifValidNickName() {
    this.validNN = (!(this.demoForm.get('cardNickName')?.valid) && this.cardNickName.length <= 0) ? true : false;
  }

  keypressEmojiStrip(e: any) {
    if (this.pattern.test(e.key)) {
      e.preventDefault();
      e.stopImmediatePropagation();
      e.stopPropagation();
    }
    if (e.target.value.length + e.key.length > 20) {
      e.preventDefault();
      e.stopImmediatePropagation();
      e.stopPropagation();
    }
    e.target.value = e.target.value.replaceAll(this.pattern, "");
  }

  keypressEmojiStripInput(e: any) {
    e.target.value = e.target.value.replaceAll(this.pattern, "");
    this.cardNickName = e.target.value;
    let len = e.target.value.split("");
    if (len.length > 20) {
      e.target.value = '';
      for (var i = 0; i < 20; i++) {
        e.target.value += len[i];
      }
      this.cardNickName = e.target.value;
      e.preventDefault();
      e.stopImmediatePropagation();
      e.stopPropagation();
    }
  }
  onChange(text: string) {
    let length = text.length;
    if (length > 20) {
      let temp = '';
      this.demoForm.patchValue({
        cardNickName: ''
      });
      temp = text.slice(0, 20);
      this.demoForm.patchValue({
        cardNickName: temp
      });
    } else {
      this.demoForm.patchValue({
        cardNickName: text
      });
    }
  }
  getErrorExpDate() {
    if (this.demoForm?.get('expDate')?.hasError('required')) {
      return 'required';
    } else if (this.demoForm?.get('expDate')?.hasError('expDate')) {
      return 'expDate';
    } else {
      return '';
    }
  }
  public prevLength: number = 0;
  public prevValue: string = "";
  public onInput(event: any, inputName: string) {
    if (inputName === "cardNickName" && this.prevLength <= 0) {
      this.prevLength = 10;
    }
    if (event.target.value && (event.target.value.length - this.prevLength) >= 3) {
      event.target.value = this.prevValue;
      event.stopImmediatePropagation();
      event.stopPropagation();
      event.preventDefault();
    }
    this.prevLength = event.target.value.length;
    this.prevValue = event.target.value;
    if (event.ctrlKey || (event.key?.toLowerCase() === 'v') || event?.data?.length >= 2) {
      event.stopImmediatePropagation();
      event.stopPropagation();
      event.preventDefault();
    }
  }
  onFocusInBtnCC() {
    this.isCCError = false;
  }
  onFocusOutBtnCC() {
    if ((!this.demoForm.controls?.['creditCard']?.valid && this.demoForm.controls?.['creditCard']?.dirty)
      || (this.demoForm.controls?.['creditCard']?.hasError('ccNumber') && this.demoForm.controls?.['creditCard']?.touched)) {
      this.isCCError = true;
    } else {
      this.isCCError = false;
    }
  }
  onFocusInBtnExp() {
    this.isExpError = false;
  }
  onFocusOutBtnExp() {
    if ((!this.demoForm.controls?.['expDate']?.valid && this.demoForm.controls?.['expDate']?.dirty)
      || (this.demoForm.controls?.['expDate']?.invalid && this.demoForm.controls?.['expDate']?.touched)) {
      this.isExpError = true;
    } else {
      this.isExpError = false;
    }
  }
  onFocusInBtnNickName() {
    this.isNickNameError = false;
  }
  onFocusOutBtnNickName() {
    if (this.demoForm.controls?.['cardNickName']?.invalid && this.cardNickName.length <= 0) {
      this.isNickNameError = true;
    } else {
      this.isNickNameError = false;
    }
  }
  disableCutCopy(event: any) {
    event.preventDefault();
    navigator.clipboard.writeText('').then(function () {
      log("Copying and Cutting is disabled");
    }, function (err) {
      log("unable to get clipboard");
    })
  }

  onSendGA() {
    const data = {
      "event": {
        "action": "Click",
        "category": "PlayCard_CardActivate_Input",
        "label": "PlayCard_CardActivate_Input_Next"
      },
      "customDimensions": [
        {
            "key": "event_category",
            "value": "PlayCard_CardActivate_Input"
        }
      ]
    }
    log(data);
    sendGADataToNative(data)
  }
}
