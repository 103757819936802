import { BsModalRef } from 'ngx-bootstrap/modal';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-popup-pin-not-same',
  templateUrl: './popup-pin-not-same.component.html',
  styleUrls: ['./popup-pin-not-same.component.css']
})
export class PopupPinNotSameComponent implements OnInit {

  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit(): void {
  }

}
