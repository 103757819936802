<div class="modal-header border-0">
  <h4 class="modal-title">ไม่สามารถเพิ่มบัตรได้</h4>
</div>
<div class="modal-body">
  คุณเพิ่มบัตรครบ 10 ใบแล้ว หากต้องการเพิ่มบัตรใหม่ กรุณาปิดบัตรที่ไม่ใช้งานก่อน
</div>
<div class="modal-footer border-0">
  <button
    type="button"
    class="btn btn-default submitStyle"
    (click)="bsModalRef.hide()"
  >
    ตกลง
  </button>
</div>
