import { Accept, AcceptResponse } from './../interfaces/accept.TnC';
import { map } from 'rxjs/operators';
import { Content } from './../interfaces/content.TnC';
import { TncServiceService } from './../services/tnc-service.service';
import { Latest } from './../interfaces/latest.TnC';
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { tap } from 'rxjs';
import { Update } from '../interfaces/update.TnC';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { log } from '../utilities';

@Component({
  selector: 'app-tnc',
  templateUrl: './tnc.component.html',
  styleUrls: ['./tnc.component.css']
})

export class TncComponent implements OnInit {
  isDisabled: boolean = false;
  latestTnC: Latest = {} as Latest;
  public updatedTnC: Update = {} as Update;
  contentTnC: Content = {} as Content;
  public contentDesc: SafeHtml | undefined;
  public sid: string = '';
  public crn: string = '';
  public authToken: string = '';
  public wvt: string = '';
  public nextAction: string = '';
  public AcceptTnCObj: AcceptResponse = {} as AcceptResponse;
  public showActionArea: boolean = false;
  constructor(
    private _location: Location,
    private _tncService: TncServiceService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private sanitizer: DomSanitizer
  ) {
    this.activatedRoute.queryParams.subscribe(prm => {
      this.sid = prm['sessionId'];
      this.crn = prm['cardRefNo'];
      this.authToken = prm['authorizationCode'];
      this.wvt = prm['webviewToken'];
      this.nextAction = prm['nextAction'];
    });
  }
  backClicked() {
    this._location.back();
  }

  ngOnInit(): void {
    const gLTnC = this._tncService.getUpdatedTnC(this.sid);
    gLTnC.pipe(
      tap(tc => this.updatedTnC = tc),
      map(tc => this.updatedTnC = tc)
    ).subscribe(tc => {
      log("TnC Response->", tc);
      if (tc.tncList && tc.tncList[0].description) {
        let cd: string = tc.tncList[0]?.description ?? '<strong style="font-size:14px">Description is not loaded</strong>';
        cd = cd.replace("font-size:14px", "font-size:16px; line-height:24px;");
        cd = cd.replace("strong", "strong class='tncContentHeader'");
        this.contentDesc = this.sanitizer.bypassSecurityTrustHtml(
          cd
        );
      }
      if (tc.tncList[0].isUpdateTc) {
        this.showActionArea = true;
      } else {
        this.showActionArea = false;
      }
      ///-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*
      // If the content of the TnC is loaded from the URL in tncList
      ///-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*
      // const gTnCCntnt = this._tncService.getTnCContent(tc.tncList[0].tncUrl);
      // gTnCCntnt.pipe(
      //   tap(tncData => this.contentTnC = tncData),
      //   map(tncData => this.contentTnC = tncData)
      // ).subscribe(tncData => { this.contentTnC = tncData });
    });
  }
  ngAfterViewInit(){
    // document.body.scrollTo(0, 0)
    document.body.scrollTop = 0
  }
  acceptTnC() {
    let accReq: Accept = {
      "cid": "",
      "cardRefNo": [this.crn],
      "tncName": this.updatedTnC.tncList[0]?.tncName ?? 'NoName',
      "tncVersion": this.updatedTnC.tncList[0]?.tncVersion ?? '0.0.0'
    }
    log("Line 96->", accReq, this.nextAction);
    const atnc = this._tncService.acceptTnC(accReq, this.sid);
    atnc.pipe(
      tap(atc => this.AcceptTnCObj = atc),
      map(atc => this.AcceptTnCObj = atc)
    ).subscribe((atc: any) => {
      this.AcceptTnCObj = atc
      log("Accept TnC response ->", atc, "Accept TnC response Object", this.AcceptTnCObj);
      log("WebViewToken Value -> ", this.wvt)
      if (atc.statusCd === "0000" || this.AcceptTnCObj.statusCd === '0000') {
        this.router.navigate([this.nextAction], { queryParams: { sessionId: this.sid, cardRefNo: this.crn, webviewToken: this.wvt, authorizationCode: this.authToken } });
      }
    })
  }
}
