import { PopupEasyPinComponent } from './../popup-easy-pin/popup-easy-pin.component';
import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CardInfo } from '../interfaces/detail.Card';
import { take } from 'rxjs';
import isOnline from 'is-online';
import { PopUpNoInternetComponent } from '../pop-up-no-internet/pop-up-no-internet.component';
import { log } from '../utilities';

@Component({
  selector: 'app-atmset-pin',
  templateUrl: './atmset-pin.component.html',
  styleUrls: ['./atmset-pin.component.css']
})
export class ATMSetPinComponent implements OnInit {

  webviewToken: string = "";
  authToken: string = "";
  sid: string = "";
  cardRefNo: string = "";
  cardNickName: string = "";
  ATMPin: string = "";
  networkStatus: boolean = false;
  public isFilled: boolean[] = [false, false, false, false, false, false];
  cardDetail: CardInfo = {} as CardInfo;
  bsModalRef?: BsModalRef;
  public innerHeight: any;
  public innerWidth: any;

  constructor(public _location: Location,
    public _router: Router,
    private activatedRoute: ActivatedRoute,
    private modalService: BsModalService,
  ) {
    this.activatedRoute.queryParams.subscribe(prm => {
      this.authToken = prm['authorizationCode'];
      this.cardRefNo = prm['cardRefNo'];
      this.webviewToken = prm['webviewToken'];
      this.sid = prm['sessionId'];
    });
    var state = this._location.getState() as { navigationId: number, cardInfo: CardInfo, cardnickname: string };
    this.cardDetail = state.cardInfo;
    this.cardNickName = state.cardnickname;
    this.innerHeight = window.innerHeight;
    this.innerWidth = window.innerWidth;
  }
  ngOnInit(): void {
    const ua = navigator.userAgent.toLowerCase();
    const isAndroid = ua.indexOf('android') > -1;

    if (!isAndroid) {
      document
        .getElementsByClassName('keyboard')[0]
        ?.classList.add('keyboard-bottom');
    }
    (async () => { this.networkStatus = await isOnline(); })();
  }
  backClicked() {
    this._location.back();
  }

  isWarningPINMirrorDigits(pin: string): boolean {
    var revPin = pin.split('').reverse().join('');
    if (revPin === pin) return true
    return false;
  }
  isWarningPINConsecutiveDigit(pin: string): boolean {
    var isMatched = pin.match(/(\w)(\1*)/g) || [];
    for (let match of isMatched) {
      if (match.length > 1) return true;
    }
    return false;
  }
  isWarningPINRepeat3Digit(pin: string): boolean {
    var ThreeCharStrings = pin.match(/.{1,3}/g) || [];
    var firstThree = ThreeCharStrings[0] || "";
    var secondThree = ThreeCharStrings[1] || "";
    if (firstThree?.split('').every(c => c === firstThree[0]) || secondThree?.split('').every(c => c === secondThree[0])) {
      return true
    }
    return false;
  }
  isWarningPIN222(pin: string): boolean {
    var TwoCharStrings = pin.match(/.{1,2}/g) || [];
    if (TwoCharStrings[0] === TwoCharStrings[1] || TwoCharStrings[1] === TwoCharStrings[2]) {
      return true
    }
    return false;
  }
  isWarningPIN33(pin: string): boolean {
    var ThreeCharStrings = pin.match(/.{1,3}/g) || [];
    if (ThreeCharStrings[0] === ThreeCharStrings[1]) {
      return true
    }
    return false;
  }
  isConsecutive(pin: string) {
    const ascendingOrder = (a: number, b: number) => a - b === -1;
    const descendingOrder = (a: number, b: number) => ascendingOrder(b, a);
    const consecutiveChecker = (array: number[], consecutiveFilter: any) => {
      for (let i = 1; i < array.length; i++) {
        if (!consecutiveFilter(array[i - 1], array[i])) {
          return false;
        }
      }
      return true;
    }
    const consecutiveAscOrDescChecker = (array: number[]) => consecutiveChecker(array, ascendingOrder) || consecutiveChecker(array, descendingOrder);
    var pinNumbers = pin.split("").map(x => parseInt(x));
    return consecutiveAscOrDescChecker(pinNumbers) ? true : false;
  }

  ATMPinNoClicked(inputtedNo: number) {
    if (inputtedNo === -1) {
      this.isFilled[this.ATMPin.length - 1] = false;
      this.ATMPin = this.ATMPin.slice(0, -1);
    } else {
      this.ATMPin += inputtedNo.toString();
      this.isFilled[this.ATMPin.length - 1] = true;
      if (this.ATMPin.length >= 6) {
        if (this.isWarningPIN33(this.ATMPin)
          || this.isWarningPIN222(this.ATMPin)
          || this.isWarningPINRepeat3Digit(this.ATMPin)
          || this.isWarningPINConsecutiveDigit(this.ATMPin)
          || this.isWarningPINMirrorDigits(this.ATMPin)
          || this.isConsecutive(this.ATMPin)) {
          this.bsModalRef = this.modalService.show(PopupEasyPinComponent, { class: 'modal-dialog-centered' });
          this.bsModalRef.onHide?.pipe(take(1)).subscribe(() => {
            log(this.bsModalRef?.content);
            if (this.bsModalRef?.content === "continue") {
              this.submitATMPin();
            } else {
              this.reset();
            }
          })
        } else {
          this.submitATMPin();
        }
      }
    }
  }
  reset() {
    this.ATMPin = "";
    this.isFilled = [false, false, false, false, false, false];
  }
  async submitATMPin() {
    log("submit now -> Setting the ATM Pin");
    this.networkStatus = await isOnline();
    if (this.networkStatus) {
      this._router.navigate(['/atm-confirm-pin'], {
        state: {
          pin: this.ATMPin,
          cardInfo: this.cardDetail,
          cardnickname: this.cardNickName
        },
        queryParams: {
          sessionId: this.sid,
          cardRefNo: this.cardRefNo,
          webviewToken: this.webviewToken,
          authorizationCode: this.authToken
        }
      });
    } else {
      this.bsModalRef = this.modalService.show(PopUpNoInternetComponent, { class: 'modal-dialog-centered' });
    }
  }
  rowRatio() {
    let ratio = this.innerHeight / this.innerWidth;
    log(ratio);
    if (ratio <= 1.5) {
      return "3:1";
    } else if (ratio <= 1.8) {
      return "2.5:1";
    } else {
      return "2:1";
    }
  }
}
