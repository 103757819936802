<div class="modal-header border-0">
  <h4 class="modal-title">ไม่สามารถเปิดใช้งานบัตรได้</h4>
</div>
<div class="modal-body">
  กรุณาตรวจสอบข้อมูลหรือประเภทบัตรให้ถูกต้อง
  <br />
  และลองใหม่อีกครั้ง {{ statusCode }}
</div>
<div class="modal-footer border-0">
  <button
    type="button"
    class="btn btn-default submitStyle"
    (click)="bsModalRef.hide()"
  >
    ตกลง
  </button>
</div>
