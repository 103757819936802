import { isDevMode } from "@angular/core"

export const isIosGreaterThan14OrAndroid = () => {
    let userAgent = window.navigator.userAgent
    if (/iPhone OS (\d+)_(\d+)_?(\d+)?/.test(userAgent)) {
        const iosVersion = parseInt(RegExp.$1, 10)
        return iosVersion >= 15
    }
    return true
}

export const log = (...e: any[]) => {
    if (isDevMode()) {
        console.log(e)
    }
}