<mat-grid-list cols="3" rowHeight="60" class="header-box">
  <mat-grid-tile>
    <div style="width: 100%; margin-left: 22px">
      <img src="/assets/BackIcon.png" height="20" />
    </div>
  </mat-grid-tile>
  <mat-grid-tile
    style="
      font-family: 'Kanit';
      font-weight: 600;
      font-size: 20px;
      color: #212121;
    "
  >
    เปิดบัตร Play
  </mat-grid-tile>
  <mat-grid-tile>&nbsp;</mat-grid-tile>
</mat-grid-list>
<div
  style="
    border-radius: 12px 12px 0px 0px;
    min-height: 100vh;
    background-color: #ffffff;
  "
>
  <div style="margin-left: 16px; margin-right: 16px">
    <img
      src="/assets/PaotangPayPlay.png"
      height="200"
      style="
        padding-top: 16px;
        margin-left: auto;
        margin-right: auto;
        display: block;
      "
    />
    <p
      style="
        font-family: 'Kanit';
        font-size: 16px;
        font-weight: 400;
        color: #565656;
        margin-top: 24px;
      "
    >
      กรุณากรอกข้อมูลบนหลังบัตร Play ที่คุณต้องการ เปิด
    </p>

    <mat-divider></mat-divider>


  </div>
</div>
