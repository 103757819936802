import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-pop-up-no-internet',
  templateUrl: './pop-up-no-internet.component.html',
  styleUrls: ['./pop-up-no-internet.component.css']
})
export class PopUpNoInternetComponent implements OnInit {
  public errorCode: string = '';

  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit(): void {
    const ua = navigator.userAgent.toLowerCase();
    const isAndroid = ua.indexOf('android') > -1;
    
    if (isAndroid) {
      this.errorCode = '[CA092]';
    } else {
      this.errorCode = '(CI005)';
    }
  }

}
