import { CardBindConfirmComponent } from './card-bind-confirm/card-bind-confirm.component';
import { ATMConfirmPinComponent } from './atmconfirm-pin/atmconfirm-pin.component';
import { ATMSetPinComponent } from './atmset-pin/atmset-pin.component';
import { CardBindComponent } from './card-bind/card-bind.component';
import { TncComponent } from './tnc/tnc.component';
import { InquiryCardDetailComponent } from './inquiry-card-detail/inquiry-card-detail.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'card-binding' },
  { path: 'cardInfo', component: InquiryCardDetailComponent, canActivate: [AuthGuard] },
  { path: 'cardInfoTnC', component: TncComponent, canActivate: [AuthGuard] },
  { path: 'card-binding', component: CardBindComponent, canActivate: [AuthGuard] },
  { path: 'card-bind-confirm', component: CardBindConfirmComponent, canActivate: [AuthGuard] },
  { path: 'atm-set-pin', component: ATMSetPinComponent, canActivate: [AuthGuard] },
  { path: 'atm-confirm-pin', component: ATMConfirmPinComponent, canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

// { path: 'cardInfo', component: InquiryCardDetailComponent, canActivate: [AuthGuard] },
// { path: 'cardInfoTnC', component: TncComponent, canActivate: [AuthGuard] },
// { path: 'card-binding', component: CardBindComponent, canActivate: [AuthGuard] },
// { path: 'card-bind-confirm', component: CardBindConfirmComponent, canActivate: [AuthGuard] },
// { path: 'atm-set-pin', component: ATMSetPinComponent, canActivate: [AuthGuard] },
// { path: 'atm-confirm-pin', component: ATMConfirmPinComponent, canActivate: [AuthGuard] }

// { path: 'cardInfo', component: InquiryCardDetailComponent, canActivate: [AuthGuard], data: { animation: 1 } },
// { path: 'cardInfoTnC', component: TncComponent, canActivate: [AuthGuard], data: { animation: 2 } },
// { path: 'card-binding', component: CardBindComponent, canActivate: [AuthGuard], data: { animation: 3 } },
// { path: 'card-bind-confirm', component: CardBindConfirmComponent, canActivate: [AuthGuard], data: { animation: 4 } },
// { path: 'atm-set-pin', component: ATMSetPinComponent, canActivate: [AuthGuard], data: { animation: 5 } },
// { path: 'atm-confirm-pin', component: ATMConfirmPinComponent, canActivate: [AuthGuard], data: { animation: 6 } }
