import { session } from './../interfaces/get-token.Auth';
import { Detail, LookupRequest, LookupResponse } from './../interfaces/detail.Card';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { SessionStatus } from '../interfaces/session-status';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  })
};

@Injectable({
  providedIn: 'root'
})
export class CardService {

  public apiURL: string = '';
  constructor(private _httpClient: HttpClient) {
    this.apiURL = environment.apiUrl;
  }

  getCardList(citizenId: string, cifNo: string, sid: string): any {
    let httpOptionsWithSession = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'sessionId': sid
      })
    }
    return this._httpClient.post<any>(this.apiURL + '/card-platform/v1/inquiry/card/list', { citizenId, cifNo }, httpOptionsWithSession)
  }
}
