import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PageLayoutComponent } from './page-layout/page-layout.component';
import { PageHeaderComponent } from './page-layout/page-header/page-header.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ModalModule } from 'ngx-bootstrap/modal';

import { MatGridListModule } from '@angular/material/grid-list';
import { MatDividerModule } from '@angular/material/divider';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { InquiryCardDetailComponent } from './inquiry-card-detail/inquiry-card-detail.component';
import { ChangeCardNameComponent } from './change-card-name/change-card-name.component';
import { TncComponent } from './tnc/tnc.component';
import { CardBindComponent } from './card-bind/card-bind.component';
import { CreditCardDirectivesModule } from 'angular-cc-library';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ATMSetPinComponent } from './atmset-pin/atmset-pin.component';
import { ATMConfirmPinComponent } from './atmconfirm-pin/atmconfirm-pin.component';
import { CardBindConfirmComponent } from './card-bind-confirm/card-bind-confirm.component';
import { CcFormatDirective } from './directive/cc-format.directive';
import { PopUpCardNotActivatedComponent } from './pop-up-card-not-activated/pop-up-card-not-activated.component';
import { PopUpNoInternetComponent } from './pop-up-no-internet/pop-up-no-internet.component';
import { DatePipe } from '@angular/common';
import { PopupEasyPinComponent } from './popup-easy-pin/popup-easy-pin.component';
import { PopupPinNotSameComponent } from './popup-pin-not-same/popup-pin-not-same.component';
import { PopUpMaximumCardComponent } from './pop-up-maximum-card/pop-up-maximum-card.component';
import { PopUpErrorCodeComponent } from './pop-up-error-code/pop-up-error-code.component';
import { EventInterceptorService } from './services/event-interceptor.service';
import { AddTagDirective } from './directive/add-tag.directive';
import { SpacePipe } from './pipes/space.pipe';
import { NgDialogAnimationService } from 'ng-dialog-animation';
import { LoaderComponent } from './shared/loading/loader/loader.component';
import { LoaderService } from './shared/loading/loader.service';
import { NgxSpinnerModule } from 'ngx-spinner';

// for support ios 8-13
const disableAnimations =
  !('animate' in document.documentElement)
  || (navigator && /iPhone OS (8|9|10|11|12|13)_/.test(navigator.userAgent));
  
@NgModule({
  declarations: [
    AddTagDirective,
    AppComponent,
    ATMConfirmPinComponent,
    ATMSetPinComponent,
    CardBindComponent,
    CardBindConfirmComponent,
    CcFormatDirective,
    ChangeCardNameComponent,
    InquiryCardDetailComponent,
    LoaderComponent,
    PageHeaderComponent,
    PageLayoutComponent,
    PopUpCardNotActivatedComponent,
    PopUpErrorCodeComponent,
    PopUpMaximumCardComponent,
    PopUpNoInternetComponent,
    PopupEasyPinComponent,
    PopupPinNotSameComponent,
    SpacePipe,
    TncComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule.withConfig({ disableAnimations }),
    MatGridListModule,
    MatDividerModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    CreditCardDirectivesModule,
    TooltipModule.forRoot(),
    ModalModule.forRoot(),
    NgxSpinnerModule
  ],
  providers: [
    DatePipe,
    LoaderService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: EventInterceptorService,
      multi: true
    },
    NgDialogAnimationService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
