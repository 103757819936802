import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  Renderer2,
} from '@angular/core';

@Directive({
  selector: '[addTag]',
})
export class AddTagDirective {
  constructor(private elem: ElementRef, private _renderer: Renderer2) {}

  ngAfterViewInit() {
    const newText = this.replaceTag(this.elem.nativeElement.innerText);
    this._renderer.setProperty(this.elem.nativeElement, 'innerHTML', newText);
  }

  private replaceTag(text: string, defaultTag: string = 'span') {
    const pattern: RegExp = /[\d | \/]/gu;
    const newText: string = text.trim().replace(pattern, (match, index) => {
      if (match === "/") {
        const speak = 'ทับ';
        return `<${defaultTag} aria-label="${speak}">${match}</${defaultTag}>`
      }
      return `<${defaultTag}>${match}</${defaultTag}>`;
    });
    return newText;
  }
}
