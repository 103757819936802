<div class="modal-header border-0">
  <h4 class="modal-title">
    รหัสบัตรของคุณคาดเดาง่ายเกินไป ต้องการดำเนินการต่อด้วยรหัสนี้?
  </h4>
</div>
<div class="modal-body">
  <span>คุณสามารถตั้งรหัสใหม่เพื่อเพิ่ม<br />ความปลอดภัยให้กับบัตรของคุณ</span>
</div>
<div class="modal-footer border-0">
  <div class="row">
    <div class="col-6">
      <button
        name="btnReset"
        type="button"
        class="btn btn-default btn-style"
        (click)="resetClicked()"
      >
        ตั้งรหัสใหม่
      </button>
    </div>
    <div class="col-6">
      <button
        name="btnContinue"
        type="button"
        class="btn btn-default submitStyle"
        (click)="continueClicked()"
      >
        ดำเนินการต่อ
      </button>
    </div>
  </div>
</div>
