<div class="modal-header border-0">
  <h4 class="modal-title">ขออภัยค่ะ</h4>
</div>
<div class="modal-body">
  ไม่พบสัญญาณอินเทอร์เน็ต กรุณาตรวจสอบการเชื่อมต่อสัญญาณอินเทอร์เน็ตอีกครั้งค่ะ {{errorCode}}
</div>
<div class="modal-footer border-0">
  <button
    type="button"
    class="btn btn-default submitStyle"
    (click)="bsModalRef.hide()"
  >
    ตกลง
  </button>
</div>
