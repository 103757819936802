import { PopupPinNotSameComponent } from './../popup-pin-not-same/popup-pin-not-same.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import jwt_decode from 'jwt-decode';
import { CardInfo } from './../interfaces/detail.Card';
import { ActivatedRoute, Router } from '@angular/router';
import { UpdateWebSubmitService } from './../services/update-web-submit.service';
import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { WebSubmitRequest, WebSubmitResponse } from '../interfaces/web-submit';
import isOnline from 'is-online';
import { log } from '../utilities';

@Component({
  selector: 'app-atmconfirm-pin',
  templateUrl: './atmconfirm-pin.component.html',
  styleUrls: ['./atmconfirm-pin.component.css']
})
export class ATMConfirmPinComponent implements OnInit {
  ATMPin: string = "";
  public isFilled: boolean[] = [false, false, false, false, false, false];
  public setPin: string = "";
  public BindRefID: string = "";
  webviewToken: string = "";
  authToken: string = "";
  sid: string = "";
  cardRefNo: string = "";
  cardDetail: CardInfo = {} as CardInfo;
  networkStatus: boolean = false;
  cardNickName: string = "";
  public decodedwebViewToken: any = {};
  bsModalRef?: BsModalRef;
  unmatchedPinCount: number = -1;
  errorMessageArray: string[] = [
    "ใส่รหัสบัตรไม่ถูกต้อง สามารถลองใหม่ได้อีก 4 ครั้ง",
    "ใส่รหัสบัตรไม่ถูกต้อง สามารถลองใหม่ได้อีก 3 ครั้ง",
    `ใส่รหัสบัตรไม่ถูกต้อง สามารถลองใหม่ได้อีก 2 ครั้ง </br> หรือกดย้อนกลับเพื่อตั้งรหัสบัตรใหม่`,
    `ใส่รหัสบัตรไม่ถูกต้อง สามารถลองใหม่ได้อีก 1 ครั้ง </br> หรือกดย้อนกลับเพื่อตั้งรหัสบัตรใหม่`
  ];
  public innerHeight: any;
  public innerWidth: any;

  constructor(
    public _location: Location,
    private uws: UpdateWebSubmitService,
    private activatedRoute: ActivatedRoute,
    private modalService: BsModalService,
    private router: Router
  ) {
    this.activatedRoute.queryParams.subscribe(prm => {
      this.authToken = prm['authorizationCode'];
      this.cardRefNo = prm['cardRefNo'];
      this.webviewToken = prm['webviewToken'];
      this.sid = prm['sessionId'];
    });
    this.innerHeight = window.innerHeight;
    this.innerWidth = window.innerWidth;
  }

  ngOnInit(): void {
    const ua = navigator.userAgent.toLowerCase();
    const isAndroid = ua.indexOf('android') > -1;

    if (!isAndroid) {
      document
        .getElementsByClassName('keyboard')[0]
        ?.classList.add('keyboard-bottom');
    }
    (async () => { this.networkStatus = await isOnline(); })();
    var stateObj = this._location.getState() as { navigationId: number, pin: string, cardInfo: CardInfo, cardnickname: string };
    this.setPin = stateObj.pin as string;
    this.cardDetail = stateObj.cardInfo;
    this.cardNickName = stateObj.cardnickname;
    this.decodedwebViewToken = jwt_decode(this.webviewToken);
  }
  backClicked() {
    this._location.back();
  }
  ATMPinNoClicked(inputtedNo: number) {
    if (inputtedNo === -1) {
      this.isFilled[this.ATMPin.length - 1] = false;
      this.ATMPin = this.ATMPin.slice(0, -1);
    } else {
      this.ATMPin += inputtedNo.toString();
      this.isFilled[this.ATMPin.length - 1] = true;
      if (this.ATMPin.length >= 6) {
        if (this.ATMPin !== this.setPin) {
          this.unmatchedPinCount++;
          if (this.unmatchedPinCount >= 4) {
            this.bsModalRef = this.modalService.show(PopupPinNotSameComponent, { class: 'modal-dialog-centered' });
            this.bsModalRef.onHide?.subscribe(() => {
              //go back to set atm
              this.router.navigate(['/atm-set-pin'], {
                state: {
                  cardInfo: this.cardDetail,
                  cardnickname: this.cardNickName
                },
                queryParams: {
                  sessionId: this.sid,
                  cardRefNo: this.cardRefNo,
                  webviewToken: this.webviewToken,
                  authorizationCode: this.authToken
                }
              });
            })
          } else {
            this.reset();
          }
        } else {
          this.submitATMPin();
        }
      }
    }
  }
  reset() {
    this.ATMPin = "";
    this.isFilled = [false, false, false, false, false, false];
  }
  async submitATMPin() {
    log("submit now -> Confirming ATM Pin");
    let wsReq: WebSubmitRequest = {
      atmPin: this.ATMPin,
      cardNickName: this.cardNickName,
      cardNo: this.cardDetail.cardNo,
      cardUsageLimitSpending: this.cardDetail.cardUsageLimitSpending,
      cardUsageLimitWithdrawATM: this.cardDetail.cardUsageLimitWithdrawATM,
      expiryDate: this.cardDetail.cardExpiryDate,
      maskedCardNo: this.cardDetail.maskCardNo,
      cardHolderNameEN: this.cardDetail.cardHolderNameEN
    };
    this.uws.submitWeb(wsReq, this.sid)
      .subscribe((sw: WebSubmitResponse) => {
        log("WebSubmitResponse -> ", sw);
        this.BindRefID = sw.bindingRefId;
        log(this.decodedwebViewToken.endflowUrl + "?bindingRefId=" + this.BindRefID + "&isExist=");
        window.location.href = this.decodedwebViewToken.endflowUrl + "?bindingRefId=" + this.BindRefID + "&isExist=true";
      });
  }
  rowRatio() {
    let ratio = this.innerHeight / this.innerWidth;
    log(ratio);
    if (ratio <= 1.5) {
      return "3:1";
    } else if (ratio <= 1.8) {
      return "2.5:1";
    } else {
      return "2:1";
    }
  }
}
