import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'SpacePipe'
})
export class SpacePipe implements PipeTransform {

  transform(value: string, args?: any): any {
    if (!value) {
      return null;
    }
    let result = value.split('');
    if (args) {
      if(args === 'slash') {
        result = result.map(item => {
          if (item === '/') {
            item = 'ทับ';
          } 
          return item;
        });
      }
    }
    return result.join(' ').trim();
  }

}
