<div class="header-box">
  <div class="row">
    <div class="col col-2" (click)="backClicked()">
      <img src="/assets/BackIcon.png" height="20" />
    </div>
    <div class="col text-center page-header">ตั้งรหัสบัตรใหม่</div>
    <div class="col col-2"></div>
  </div>
</div>
<div class="page-body">
  <img src="/assets/PaotangPayPlay.png" class="card-Image" />
  <p class="ATMHeading">ตั้งรหัสบัตร 6 หลักใหม่</p>
  <p class="ATMSubHeading">สำหรับใช้งานที่เครื่องรูดบัตรเท่านั้น</p>
  <div class="row gx-2 m-3">
    <div
      class="col col-2 pin-number"
      *ngFor="let pinNo of [0, 1, 2, 3, 4, 5]; let i = index"
    >
      <img
        [src]="isFilled[i] ? '/assets/pinFilled.png' : '/assets/pinField.png'"
        height="64"
      />
    </div>
  </div>
</div>
<div class="fixed-bottom keyboard">
  <mat-grid-list cols="3" [rowHeight]="rowRatio()">
    <mat-grid-tile (click)="ATMPinNoClicked(1)">1</mat-grid-tile>
    <mat-grid-tile (click)="ATMPinNoClicked(2)">2</mat-grid-tile>
    <mat-grid-tile (click)="ATMPinNoClicked(3)">3</mat-grid-tile>
    <mat-grid-tile (click)="ATMPinNoClicked(4)">4</mat-grid-tile>
    <mat-grid-tile (click)="ATMPinNoClicked(5)">5</mat-grid-tile>
    <mat-grid-tile (click)="ATMPinNoClicked(6)">6</mat-grid-tile>
    <mat-grid-tile (click)="ATMPinNoClicked(7)">7</mat-grid-tile>
    <mat-grid-tile (click)="ATMPinNoClicked(8)">8</mat-grid-tile>
    <mat-grid-tile (click)="ATMPinNoClicked(9)">9</mat-grid-tile>
    <mat-grid-tile>&nbsp;</mat-grid-tile>
    <mat-grid-tile (click)="ATMPinNoClicked(0)">0</mat-grid-tile>
    <mat-grid-tile (click)="ATMPinNoClicked(-1)">
      <img src="/assets/ic-delete-back.png" height="24" />
    </mat-grid-tile>
  </mat-grid-list>
</div>
