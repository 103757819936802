import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { GetAuthorizeService } from '../services/get-authorize.service';
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private authFetch: GetAuthorizeService,
  ) {



  }

  async canActivate(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {
    let result = true;
    let paramString = state.url.split('?')[1];
    let urlParams = new URLSearchParams(paramString);
    const authorizationCode = urlParams.get('authorizationCode') || '';
    const webviewToken = urlParams.get('webviewToken') || '';
    sessionStorage.setItem('webviewToken', webviewToken);
    let sessionID = sessionStorage.getItem('sessionID');
    if (sessionID) {
      return true;
    }
    if (authorizationCode != null && authorizationCode != '' && authorizationCode != undefined && (sessionID == '' || sessionID == null || sessionID == undefined)) {
      await this.authFetch.refreshToken(authorizationCode);
    }
    return result;
  }



}

