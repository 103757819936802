<div class="modal-header border-0">
  <h4 class="modal-title">ไม่สามารถทำรายการได้ในขณะนี้</h4>
</div>
<div class="modal-body">กรุณาลองใหม่อีกครั้งในภายหลัง {{ errorCode }}</div>
<div class="modal-footer border-0">
  <button
    type="button"
    class="btn btn-default submitStyle"
    (click)="closeModal()"
  >
    ตกลง
  </button>
</div>
