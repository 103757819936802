import { Component, OnInit } from '@angular/core';
// import { RouterOutlet } from '@angular/router';
// import { slider } from './slider-animation';

import { isIosGreaterThan14OrAndroid } from "./utilities"

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  // animations: [slider]
})
export class AppComponent implements OnInit {
  title = 'play-card-web-ui';
  // prepareRoute(outlet: RouterOutlet) {
  //   return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  // }

  ngOnInit() {
    if (isIosGreaterThan14OrAndroid()) {
      return
    }
    window.addEventListener("keyboardWillHide", () => {
      window.scrollTo(0, 0)
      document.body.scrollTop = 0
    })
  }
}
