import { SensitiveCardDetailResponse } from './../interfaces/detail.Card';
import { map, tap } from 'rxjs/operators';
import { ChangeCardNameComponent } from './../change-card-name/change-card-name.component';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CardDetailService } from '../services/card-detail.service';
import { ActivatedRoute, Router } from '@angular/router';
import jwt_decode from "jwt-decode";
import { CardService } from '../services/card.service';
import { firstValueFrom } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PopUpMaximumCardComponent } from '../pop-up-maximum-card/pop-up-maximum-card.component';
import { DatePipe } from '@angular/common';
import { NgDialogAnimationService } from 'ng-dialog-animation';
import { isIosGreaterThan14OrAndroid, log } from "../utilities"
declare function getCardRefNo(): any;
declare function callNative(): any;
@Component({
  selector: 'app-inquiry-card-detail',
  templateUrl: './inquiry-card-detail.component.html',
  styleUrls: ['./inquiry-card-detail.component.css']
})

export class InquiryCardDetailComponent implements OnInit {
  public authToken: string = '';
  public cardRefNo: string = '';
  public webviewToken: string = '';
  public decodedwebViewToken: any = {};
  public maskCardNum: boolean = true;
  public sid: string = '';
  public nameChanged: boolean = false;
  public nameCannotChanged: boolean = false;
  carddetails: any = {};
  public sensitiveCardDetail: SensitiveCardDetailResponse = {} as SensitiveCardDetailResponse;
  isLoaded: boolean = false;
  bsModalRef?: BsModalRef;
  public secretKeyforDecrypt: any;
  public encSensitiveInfo: any = {};
  isLoading = false;

  constructor(
    public dialog: NgDialogAnimationService,
    private _cardDetailService: CardDetailService,
    private _cardService: CardService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private modalService: BsModalService,
    private datePipe: DatePipe
  ) {
    this.activatedRoute.queryParams.subscribe(prm => {
      this.authToken = prm['authorizationCode'];
      this.webviewToken = prm['webviewToken'];
    });
    callNative();
  }

  get getIsLoaded() {
    return this.isLoaded;
  }
  ngOnInit(): void {

  }
  initData() {
    this.cardRefNo = getCardRefNo();
    this.decodedwebViewToken = jwt_decode(this.webviewToken);
    // const token = this._cardDetailService.generateToken(this.authToken);
    // token.pipe(
    //   tap(sd => { this.sid = sd.sessionId; }),
    //   map(sd => { this.sid = sd.sessionId; })
    // ).subscribe(sd => {
    //   console.log(this.sid, sd);
    // });
    const cds = this._cardDetailService.getCardDetail(this.cardRefNo, this.authToken, this.webviewToken, this.sid);
    cds.subscribe((dc: any) => {
      this.isLoaded = true;
      this.carddetails = dc;
      if (this.carddetails && (this.carddetails.cardNickName === null
        || this.carddetails.cardNickName === undefined
        || this.carddetails.cardNickName.trim() === '')) {
        this.carddetails.cardNickName = "บัตร Play"
      }
    });
  }

  openDialog() {
    const dialogConfig: any = {
      width: '100%',
      maxWidth: '100vw',
      position: { bottom: '0' },
      data: {
        cardNickName: this.carddetails.cardNickName,
        cardRefNo: this.cardRefNo,
        sessionId: this.sid
      },
      autoFocus: false,
      animation: {
        to: "top",
        incomingOptions: {
          keyframeAnimationOptions: { duration: 200, easing: "ease-in" }
        },
        outgoingOptions: {
          keyframeAnimationOptions: { duration: 200, easing: "ease-out" }
        }
      }
    };
    // dialogConfig.width = "99%";
    // dialogConfig.maxWidth = "100vw";
    // dialogConfig.position = {
    //   bottom: '0',
    // };
    // dialogConfig.data = {
    //   cardNickName: this.carddetails.cardNickName,
    //   cardRefNo: this.cardRefNo,
    //   sessionId: this.sid
    // };
    // dialogConfig.autoFocus = false;


    const dgRef = this.dialog.open(ChangeCardNameComponent, dialogConfig)

    dgRef.afterClosed()
      .subscribe(result => {

        if (!isIosGreaterThan14OrAndroid()) {
          setTimeout(() => {
            window.document.body.scrollTo({ top: 0 })
          }, 10)
        }

        log(result)
        if (result && result.statusCd === "0000") {
          this.nameChanged = true;
          setTimeout(() => {
            this.nameChanged = false;
          }, 3000);
          const cds = this._cardDetailService.getCardDetail(this.cardRefNo, this.authToken, this.webviewToken, this.sid)
          cds.subscribe((dc: any) => {
            this.carddetails = dc;
            if (this.carddetails && (this.carddetails.cardNickName === null
              || this.carddetails.cardNickName === undefined
              || this.carddetails.cardNickName.trim() === '')) {
              this.carddetails.cardNickName = "บัตร Play"
            }
          })
        } else if (result === undefined) {
        } else {
          this.nameCannotChanged = true;
          setTimeout(() => {
            this.nameCannotChanged = false;
          }, 3000);
        }
      });
  }
  getColorbyStatusCode() {
    if (this.carddetails.cardStatusCode === "00") {
      return "#70B412"
    } else if (this.carddetails.cardStatusCode === "01"
      || this.carddetails.cardStatusCode === "02"
      || this.carddetails.cardStatusCode === "09"
      || this.carddetails.cardStatusCode === "17"
      || this.carddetails.cardStatusCode === "05"
      || this.carddetails.cardStatusCode === "10") {
      return '#838383';
    } else if (this.carddetails.cardStatusCode === "11" || this.carddetails.cardStatusCode === "16") {
      return '#2B77FF';
    } else if (this.carddetails.cardStatusCode === "15") {
      return '#E17F00';
    } else {
      return '#70B412';
    }
  }
  getStringbyStatusCode() {
    if (this.carddetails.cardStatusCode === "00") {
      return 'ปกติ';
    } else if (this.carddetails.cardStatusCode === "01"
      || this.carddetails.cardStatusCode === "02"
      || this.carddetails.cardStatusCode === "09"
      || this.carddetails.cardStatusCode === "17") {
      return 'อายัด';
    } else if (this.carddetails.cardStatusCode === "05") {
      return 'หมดอายุ';
    } else if (this.carddetails.cardStatusCode === "10") {
      return 'ถูกอายัด';
    } else if (this.carddetails.cardStatusCode === "11") {
      return 'ยังไม่เปิดบัตร';
    } else if (this.carddetails.cardStatusCode === "15") {
      return 'ระงับบัตรชั่วคราว';
    } else if (this.carddetails.cardStatusCode === "16") {
      return 'ใช้ในประเทศกลุ่มเสี่ยง';
    } else {
      return '';
    }
  }
  showDetailBoxforStatusCode5() {
    if (this.carddetails.cardStatusCode === "05") {
      return 'block';
    } else {
      return 'none';
    }
  }
  showDetailBoxforStatusCode11() {
    if (this.carddetails.cardStatusCode === "11") {
      return 'block';
    } else {
      return 'none';
    }
  }
  showDetailBoxforStatusCode121710() {
    if (this.carddetails.cardStatusCode === "01"
      || this.carddetails.cardStatusCode === "02"
      || this.carddetails.cardStatusCode === "17"
      || this.carddetails.cardStatusCode === "09"
      || this.carddetails.cardStatusCode === "10") {
      return 'block';
    } else {
      return 'none';
    }
  }
  showDetailBoxforStatusCode15() {
    if (this.carddetails.cardStatusCode === "15") {
      return 'block';
    } else {
      return 'none';
    }
  }
  getFormattedCardNumber(cardNo: string) {
    let cn = cardNo?.match(/.{1,4}/g);
    return cn?.join(" ")
  }
  getLast4CardNumber(cardNo: string) {
    return cardNo.slice(-4).split('').join(' ');
  }
  getFormattedCurrency(strNum: string) {
    var formatter = new Intl.NumberFormat('th-TH', {
      style: 'currency',
      currency: 'THB',
      currencyDisplay: "code"
    });
    return formatter.format(+strNum).replace('THB', '');
  }
  backClicked() {
    window.location.href = this.decodedwebViewToken?.backUrl;
  }
  openCardClicked() {
    window.location.href = "paotang://paotang.co.th/playcard-binding.webview.deeplink";
  }

  async openTnc() {
    let citizenId = '1';
    let cifNo = '1';
    this._cardService.getCardList(citizenId, cifNo, this.sid).subscribe((result: any) => {
      if (!result.isMaximumCardExceed) {
        window.location.href = "paotang://paotang.co.th/playcard-detail-register.webview.endflowurl.deeplink";
      } else {
        this.bsModalRef = this.modalService.show(PopUpMaximumCardComponent, { class: 'modal-dialog-centered' });
      }
    });
  }
  public isRowSpan() {
    var chFullName = this.carddetails.cardHolderNameEN ?? " . ";
    if (chFullName !== " . ") {
      var fName = chFullName.split(' ')[0];
      var sName = chFullName.split(' ')[1];
      if (fName.length > 20 || sName.length > 20) {
        return true;
      }
    }
    return false;
  }
  public async toggleSensitiveCardInfo() {
    this.isLoading = true;
    if (Object.keys(this.sensitiveCardDetail).length === 0) {
      this.secretKeyforDecrypt = await this._cardDetailService.generateSecretKey();
      const cdsgscd = this._cardDetailService.getSensitiveCardDetails(this.cardRefNo, this.sid);
      cdsgscd.subscribe((scdr: SensitiveCardDetailResponse) => {
        this.isLoading = false;
        this.sensitiveCardDetail = scdr;
        this.encSensitiveInfo = this._cardDetailService.decryptSensitiveCardDetails(this.secretKeyforDecrypt, this.sensitiveCardDetail);
        var pattern = /(\d{4})(\d{2})(\d{2})/;
        this.encSensitiveInfo.encExpiryDate = this.encSensitiveInfo?.encExpiryDate + "01";
        var smdate = new Date(this.encSensitiveInfo?.encExpiryDate.replace(pattern, '$1-$2-$3'));
        this.encSensitiveInfo.encExpiryDate = this.datePipe.transform(smdate, 'MM/yy')?.toString() ?? "";
        this.maskCardNum = !this.maskCardNum;

      }, error => {
        this.isLoading = false;
      });
    } else {
      this.isLoading = false;
      this.maskCardNum = !this.maskCardNum;
    }
  }
}
