import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { WebSubmitRequest } from '../interfaces/web-submit';

@Injectable({
  providedIn: 'root'
})
export class UpdateWebSubmitService {

  public apiURL: string = '';
  constructor(private _httpClient: HttpClient) {
    this.apiURL = environment.apiUrl;
  }
  submitWeb(submitReq: WebSubmitRequest, sid: string) {
    let httpOptionsWithSession = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'sessionId': sid
      })
    }
    return this._httpClient.post<any>(this.apiURL + '/card-platform/v1/inquiry/update/web/submit', submitReq, httpOptionsWithSession);
  }
}
