export enum HTTP_ERROR {
  CODE_400 = 400,
  CODE_404 = 404,
  CODE_500 = 500,
  CODE_502 = 502,
  CODE_503 = 503,
}

export enum ANDROID_ERROR_CODE {
  HCA001 = 'HCA001',
  HCA002 = 'HCA002',
  HCA003 = 'HCA003',
  HCA004 = 'HCA004',
  HCA005 = 'HCA005',
  HCA999 = 'HCA999',
}

export enum IOS_ERROR_CODE {
  HCI001 = 'HCI001',
  HCI002 = 'HCI002',
  HCI003 = 'HCI003',
  HCI004 = 'HCI004',
  HCI005 = 'HCI005',
  HCI999 = 'HCI999',
}
